$color-dark-grey-3: #a9a9a9;

.appointments-container {
  #booking-step3 {
    .book-appt-container {
      .appt_booking_helpers {
        .appt_kr_phone_code,
        .appt_mobile_part1,
        .appt_mobile_part2 {
          float: left;
          width: 30%;
        }
        .appt_kr_phone_code {
          height: 38px;
        }
        .appt_mobile_phone_seperator {
          float: left;
          line-height: 3;
          width: 5%;
          text-align: center;
        }
      }
      .accept-terms-head,
      .accept-thirdparty-head,
      .accept-privacy-head,
      .accept-sms-notification-head {
        font-weight: bold;
        &.error {
          color: $color-notice-pink;
        }
      }
      .legal_statement {
        border: 1px solid $color-dark-grey-3;
        height: 87px;
        line-height: 18px;
        overflow-x: hidden;
        overflow-y: scroll;
        padding: 5px;
      }
      .radio_group {
        margin: 9px 0 26px 0;
        input[type='radio'] {
          width: 13px;
          margin: 0;
          display: inline-block;
          height: auto;
        }
        .error {
          color: $color-notice-pink;
        }
      }
    }
  }
}

.appt-book {
  .my-appointments {
    .appointments-buttons {
      margin-top: 12px;
    }
  }
}
