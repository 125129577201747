.appointments-container {
  #booking-step3 {
    .book-appt-container {
      .appt_booking_helpers {
        .appt_kr_phone_code {
          height: 30px;
        }
        .appt_mobile_phone_seperator {
          line-height: 2;
        }
      }
      .radio_group {
        margin: 9px 0 10px 0;
      }
    }
  }
}

.appt-book {
  .appt-book-page {
    &-header {
      .appt-book-page-header-content {
        h3 {
          margin: 6px 0 5px;
        }
        .inner {
          h2 {
            font-size: 4.32vw;
            margin-top: 4.2em;
          }
          .appt-book-page-header-content__subhead {
            p {
              margin: 0;
              font-size: 3.37vw;
              line-height: 1.4;
            }
          }
        }
      }
    }
  }
}
