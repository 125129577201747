.elc-product-full {
  .elc-vto-foundation {
    &-shade-grid {
      .elc-vto {
        &-see-pair-product {
          border: 0;
          text-align: $rdirection;
          text-decoration: underline;
        }
        &-see-all-shades,
        &-see-perfect-shades {
          &.elc-button {
            [data-component] & {
              border: 0;
              text-decoration: underline;
            }
          }
        }
        &-product-name {
          font-weight: 700;
        }
        &-custom-shade-picker-item-name {
          display: inline;
          white-space: normal;
        }
      }
    }
    .elc-vto-foundation-shade-picker-wrapper {
      margin-bottom: 43px;
    }
    .elc-product-cta-wrapper {
      .elc-product-notify-me-button {
        font-size: 14px;
        height: 48px;
        line-height: normal;
      }
    }
  }
  .elc-price-formatted-wrapper {
    .elc-price-formatted {
      font-weight: 700;
    }
  }
}
