%font-medium {
  font-family: inherit !important;
}

body,
td,
th,
p,
a,
div,
span,
input,
select,
button,
textarea,
#navigation,
#footer {
  @extend %font-medium;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
li {
  @extend %font-medium;
}

.top-left,
.top-right,
a.button,
strong,
b,
form label,
fieldset span,
a.btn-add-to-bag {
  @extend %font-medium;
}

$base-kr-font-family: 'Nanum Gothic', 'Dotum', 'Golum';
