.checkout__content {
  .with-field-lookup {
    .postcode-lookup {
      width: 37%;
    }
    .find-town {
      width: 61%;
      &.form-item {
        .valid_marker,
        .invalid_marker {
          top: 2px;
          right: 0;
        }
      }
      .city-results {
        margin-right: 15px;
      }
    }
  }
}
