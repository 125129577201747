#zero_dollar_overlay {
  h3 {
    margin-top: 16px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
  .zero-dollar {
    &-buttons {
      display: flex;
      padding-top: 40px;
      .button {
        margin: 0 10px;
        color: $color-white;
        background-color: $color-black;
      }
    }
    &-success,
    &-error {
      text-align: center;
      font-size: 28px;
      padding-top: 38px;
    }
  }
}
