@if $password_strength {
  .password-field {
    &__info {
      @media #{$medium-up} {
        #signin &,
        #billing & {
          width: auto;
          #{$ldirection}: 40px;
          bottom: 45px;
        }
        #gnav_signin & {
          width: auto;
          #{$ldirection}: 150px;
          bottom: 30px;
          &:before {
            top: 93px;
            #{$rdirection}: 70px;
            -webkit-transform: rotate(270deg);
            -moz-transform: rotate(270deg);
            -o-transform: rotate(270deg);
            transform: rotate(270deg);
          }
        }
      }
    }
  }
  #registration-wrapper {
    .profile-form-container {
      .profile-info__change-pwd-link {
        @media #{$medium-up} {
          padding: 30px 0 10px;
        }
      }
    }
  }
  .profile-password-update {
    &__rules {
      li {
        color: $color-btn-grey;
        display: flex;
      }
      @media #{$medium-up} {
        font-size: 9px;
      }
    }
    &.registration-page__content {
      #foreground-node & {
        .profile-password-update__button {
          &.disabled {
            opacity: 0.2;
            cursor: not-allowed;
          }
        }
      }
    }
  }
  .device-mobile {
    .password-field {
      &__rules {
        li {
          white-space: initial;
        }
      }
    }
    #signin-block {
      .password-field {
        &__info {
          width: 310px;
        }
      }
    }
    .password-update-overlay {
      width: 375px !important;
    }
  }
}
