.store-locator-mobile-v1 {
  .locations_form_panel {
    h3.store_locator {
      font-size: 20px;
    }
    h3.city_state {
      margin: 20px 0 15px;
      font-size: 18px;
    }
    .search.search_by_location {
      float: left;
      line-height: 46px;
      margin: 0 0 0 10px;
      width: 30%;
      height: 46px;
    }
    .zip_section_container {
      float: left;
      width: 65%;
      .sbHolder {
        a.sbSelector {
          margin: 0;
        }
        ul.sbOptions {
          overflow-y: scroll !important;
          max-height: 300px !important;
          li {
            padding: 0;
          }
        }
      }
    }
  }
  .results_header_wrapper {
    margin: 20px 0 10px;
    h3.doors_results_header {
      color: #5dba98;
      margin: 20px 0 0;
      span.header-content {
        color: #a0a0a0;
        font-weight: 600;
        span.query {
          color: #5dba98;
        }
      }
    }
  }
  .locations_table_panel {
    #store_results {
      .doors_body {
        .door_row {
          border-top: 1px solid #d8d7d7;
          border-bottom: none;
          .store_info {
            .store_name {
              margin-bottom: 10px;
            }
          }
          .store_extra {
            .extra_phone {
              width: 100%;
              p {
                a.phone {
                  width: 70%;
                }
                a.phone.button {
                  width: 30%;
                  line-height: 30px;
                  margin-left: 12px;
                  min-height: 30px;
                  height: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
  .more-results-container {
    a.more-results.button {
      line-height: 30px;
    }
  }
}
