#pr-review-display {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: '예 ';
                visibility: visible;
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: '아니오 ';
                visibility: visible;
              }
            }
          }
          .pr-rd-flag-review-container {
            width: auto;
            clear: both;
            a {
              padding-left: 0;
            }
          }
        }
      }
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          &::before {
            content: '상세 검색:';
          }
        }
        .pr-rd-review-header-sorts {
          width: 100%;
          .pr-rd-sort-group {
            &::before {
              content: '정렬 기준: ';
            }
          }
        }
        .pr-rd-sort {
          background: url('/media/images/stars/down-arrow.png') no-repeat scroll 100% 4px transparent;
        }
      }
      .pr-multiselect {
        .pr-multiselect-button {
          padding: 0 9px 0 0;
          margin: 5px 8px 0 0;
          @media screen and (max-width: 320px) {
            margin: 5px 14px 0 0;
          }
        }
      }
    }
  }
}

/* Font Impementation */
#pr-write,
#pr-review-display,
.spp_page_wrap,
.spp_reviews,
#pr-reviewsnippet {
  .p-w-r {
    a {
      font-family: $base-kr-font-family;
    }
    .pr-review-snapshot-header-intro {
      .pr-headline {
        font-family: $base-kr-font-family;
      }
    }
    /* For Review Thank You page */
    .thank-you-page {
      h2.headline {
        font-family: $base-kr-font-family;
      }
    }
  }
}

.mpp-grid,
.mpp-product {
  .p-w-r {
    .pr-category-snippet__total {
      font-family: $base-kr-font-family;
    }
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-minimal {
        .pr-snippet-read-and-write {
          font-family: $base-kr-font-family;
        }
      }
    }
  }
}
/* SPP page */
#pr-spp-snippet {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      .pr-snippet-read-and-write {
        a,
        span {
          font-family: $base-kr-font-family;
        }
      }
    }
  }
}

.reviews {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      .pr-snippet-read-and-write {
        a,
        span {
          font-family: $base-kr-font-family;
        }
      }
    }
  }
}
/* To display a Review description posted by user */
.pwr_title,
#BVRRPullquoteHighlightContainer {
  font-family: $base-kr-font-family;
}

#pr-review-display {
  .p-w-r {
    .pr-review-snapshot-simple {
      .pr-snippet-read-and-write {
        a.pr-snippet-write-review-link {
          font-family: $base-kr-font-family;
        }
      }
      .pr-review-snapshot-snippets-headline {
        font-family: $base-kr-font-family;
      }
      .pr-review-snapshot-snippets {
        .pr-snippet-review-count {
          font-family: $base-kr-font-family;
        }
      }
    }
    .pr-review-snapshot {
      .pr-review-snapshot-header {
        .pr-review-snapshot-snippets {
          .pr-snippet-stars-reco-stars {
            .pr-snippet-read-and-write {
              .pr-snippet-write-review-link {
                font-family: $base-kr-font-family;
              }
            }
          }
        }
      }
      .pr-snippet-stars-reco-inline {
        .pr-snippet-read-and-write {
          .pr-snippet-review-count {
            font-family: $base-kr-font-family;
          }
        }
      }
    }
    .pr-review-display {
      .pr-rd-main-header {
        .pr-rd-sort {
          font-family: $base-kr-font-family;
        }
      }
      .pr-review {
        .pr-rd-header {
          .pr-rd-review-headline {
            font-family: $base-kr-font-family;
          }
        }
        .pr-rd-description {
          .pr-rd-description-text {
            font-family: $base-kr-font-family;
          }
          .pr-rd-right {
            .pr-rd-reviewer-details {
              p,
              time {
                font-family: $base-kr-font-family;
              }
              p {
                .pr-rd-bold {
                  font-family: $base-kr-font-family;
                }
              }
            }
          }
        }
        .pr-rd-merchant-response {
          .pr-rd-description-text {
            font-family: $base-kr-font-family;
          }
          .pr-rd-merchant-response-headline {
            strong {
              font-family: $base-kr-font-family;
            }
          }
        }
        .pr-accordion {
          .pr-accordion-btn {
            span {
              font-family: $base-kr-font-family;
            }
          }
          .pr-accordion-content {
            dl {
              dt,
              dd {
                font-family: $base-kr-font-family;
              }
            }
          }
        }
        .pr-rd-footer {
          .pr-rd-bottomline {
            span {
              font-family: $base-kr-font-family;
            }
          }
          .pr-rd-helpful-action {
            .pr-rd-helpful-text {
              font-family: $base-kr-font-family;
            }
          }
          .pr-helpful-count {
            font-family: $base-kr-font-family;
          }
          .pr-rd-flag-review-container {
            font-family: $base-kr-font-family;
          }
          .pr-modal-content {
            #pr-flag-reviews {
              .pr-flagging-radio {
                span {
                  font-family: $base-kr-font-family;
                }
              }
            }
            .pr-flag-review-btn {
              font-family: $base-kr-font-family;
            }
            .pr-control-label,
            .pr-flag-review-label {
              font-family: $base-kr-font-family;
            }
          }
        }
      }
      .pr-rd-main-footer {
        .pr-rd-content-block {
          .pr-rd-to-top {
            font-family: $base-kr-font-family;
          }
          .pr-rd-pagination-btn,
          .pr-rd-review-position span {
            font-family: $base-kr-font-family;
          }
        }
      }
    }
    .pr-snippet-reco-to-friend {
      .pr-reco-to-friend-message {
        font-family: $base-kr-font-family;
      }
      .pr-reco {
        span {
          font-family: $base-kr-font-family;
        }
      }
    }
  }
}
/* Write a Review Form */
#power_review_container {
  .product-full__name {
    font-family: $base-kr-font-family;
  }
  .pr-sub-line {
    font-family: $base-kr-font-family;
  }
}

.write_a_review__container {
  #pr-write {
    .p-w-r {
      .pr-media_image-form-group {
        display: block;
      }
      .pr-subscript {
        display: none;
      }
      .pr-header {
        .pr-header-title {
          font-family: $base-kr-font-family;
        }
      }
      .form-control {
        font-family: $base-kr-font-family;
      }
      .pr-footer {
        .pr-subscript {
          font-family: $base-kr-font-family;
        }
      }
      .thank-you-page {
        .header {
          .title,
          .subtitle {
            font-family: $base-kr-font-family;
          }
        }
        .pr-attribute {
          font-family: $base-kr-font-family;
        }
        .pr-attribute-header {
          font-family: $base-kr-font-family;
        }
        .pr-dark {
          font-family: $base-kr-font-family;
        }
        .pr-text {
          font-family: $base-kr-font-family;
          span {
            font-family: $base-kr-font-family;
          }
        }
      }
    }
    .form-group {
      span,
      label,
      .pr-helper-text {
        font-family: $base-kr-font-family;
      }
    }
  }
}

.product-list {
  .spp-product {
    .product-info {
      display: block !important;
    }
  }
}
