.social-info {
  margin: 0 auto;
  max-width: 960px;
  &__header {
    font-size: 24px;
    border-bottom: 1px solid $color-darker-grey;
    padding-bottom: 24px;
    margin-bottom: 24px;
    letter-spacing: 0.01em;
    line-height: 1.33333;
    margin-top: 24px;
  }
  &__content {
    width: 100%;
    display: inline-block;
  }
  &__connect {
    border-bottom: 1px solid $color-darker-grey;
    flex-wrap: wrap;
    margin-bottom: 15px;
    padding-bottom: 10px;
  }
  .social_image {
    margin-#{$rdirection}: 10px;
    display: inline-block;
    img {
      width: 48px;
      height: 48px;
      vertical-align: middle;
    }
  }
  .social-account {
    display: inline-block;
  }
  .social-connect {
    a {
      text-decoration: none;
    }
  }
  .social-connect,
  .social-disconnect {
    cursor: pointer;
    text-decoration: underline;
    display: inline-block;
    font-size: 16px;
    margin-#{$ldirection}: 10em;
    @media #{$medium-up} {
      margin-#{$ldirection}: 47em;
    }
  }
}

.disconnect-confirm-overlay {
  .disconnect-no,
  .disconnect-yes {
    float: #{$ldirection};
    padding: 12px;
    margin-top: 10px;
    background-color: $color-black;
    color: $color-white;
    margin-#{$ldirection}: 10px;
    cursor: pointer;
  }
  .disconnect-overlay {
    padding-top: 20px;
  }
  .disconnect-overlay-header {
    font-size: 16px;
  }
}
