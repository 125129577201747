.clear {
  clear: both;
  zoom: 1;
}

.cright {
  clear: right;
}

.cleft {
  clear: left;
}

#footer {
  .footer-top {
    .footer-top-mobile-v1 {
      padding-bottom: 0;
      .social-links {
        text-align: center;
        margin-bottom: 12px;
        a {
          float: none;
        }
      }
      .footer-trustmark {
        display: none;
      }
    }
  }
  @media only screen and (max-device-width: 480px) and (max-device-width: 568px) and (min-device-width: 320px) and (orientation: portrait) {
    .footer-bottom {
      .field-mobile-menu ul.menu {
        > li.item-4 {
          padding-right: 0px !important;
        }
        > li.item-3,
        > li.item-5 {
          clear: left;
        }
      }
    }
  }
}

body.html.front.not-logged-in {
  #footer {
    .footer-trustmark {
      display: block;
    }
  }
}

ul.product-list.spp_view {
  li.spp-product {
    .product-info {
      a.btn-favorite {
        float: right;
        width: auto;
        margin: 0 20px;
        &:hover {
          color: #fe6cb5;
        }
        span.favorite-text {
          padding: 0 0 0 25px;
          font-size: 12px;
          color: #000000;
          display: block;
          margin-top: 2px;
          &:hover {
            color: #666666;
          }
        }
      }
      p.price {
        float: left;
      }
    }
  }
}

.mobile_mpp {
  ul#mpp-product-grid {
    li.mpp-product {
      .product-info {
        .reviews {
          margin-top: 13px;
          a.btn-favorite {
            position: relative;
            float: none;
            width: auto;
            padding: 0 25px;
            color: #000000;
            &:hover {
              color: #666666;
            }
          }
        }
      }
    }
  }
}

.sbHolder.sized {
  background: url('/media/export/images/ddml_pc.png') #f1f4f5 no-repeat -115px 5px;
}

.sbHolder.sized.Jar {
  background: url('/media/export/images/jar_pc.png') #f1f4f5 no-repeat -115px 5px !important;
}

.sbHolder.sized.Square {
  background: url('/media/export/images/square_bottle_pc.png') #f1f4f5 no-repeat -115px 5px !important;
}

.sbHolder.sized.Tube {
  background: url('/media/export/images/tube_pc.png') #f1f4f5 no-repeat -115px 5px !important;
}

.sbHolder.sized.Tall {
  background: url('/media/export/images/tall_bottle_pc.png') #f1f4f5 no-repeat -115px 5px !important;
}

.sbHolder.sized.Fragrance {
  background: url('/media/export/images/fragrance_pc.png') #f1f4f5 no-repeat -115px 5px !important;
}
/* Container */
#overlay-background {
  background-color: $color-black;
  min-width: 1024px;
}

.overlay-container {
  #address_form_container {
    input {
      color: inherit;
      display: block;
      float: none;
      font-family: inherit;
      font-size: inherit;
      height: inherit;
      @include swap_direction(margin, 5px 15px 10px 0);
      position: relative;
      text-transform: inherit;
    }
    select {
      color: inherit;
      display: block;
      float: none;
      font-family: inherit;
      font-size: inherit;
      height: inherit;
      @include swap_direction(margin, 5px 15px 10px 0);
      position: relative;
      text-transform: inherit;
    }
    #profile-postal {
      width: auto;
      margin-bottom: 1em;
      .fieldset-note {
        vertical-align: middle;
        display: inline-block;
        width: 35%;
        margin-left: 5px;
      }
      .address_lookup_submit {
        width: 100%;
      }
      width: 100%;
    }
    input.input_postal_code {
      display: inline;
      @include swap_direction(margin, 4px 0 0 0);
      vertical-align: middle;
      width: 50% !important;
    }
    input.input_default {
      display: inline;
    }
    fieldset.fs {
      div.form_element.radio {
        input {
          display: inline;
          margin-right: 10px;
          width: 0;
        }
      }
    }
    .required-message {
      clear: both;
      margin-bottom: 10px;
    }
    #address-form {
      float: #{$ldirection};
      width: 270px;
      fieldset.address {
        div.phone_part {
          margin-top: 5px;
        }
      }
      h2 {
        @include swap_direction(margin, 2px 0 2px 0);
        font-size: 20px !important;
      }
      width: 100%;
      @include swap_direction(padding, 10px 10px 10px 10px);
    }
    h2 {
      float: #{$ldirection};
      clear: both;
    }
    fieldset.fs {
      .full_name_container {
        float: none;
      }
      .address1_container {
        float: none;
        clear: both;
      }
      .address2_container {
        float: none;
      }
      .address3_container {
        float: none;
      }
      div.phone_part_area_code {
        select {
          width: 100% !important;
          float: #{$ldirection};
        }
        width: 35%;
      }
      div.form_element {
        input {
          width: 100%;
        }
        @include swap_direction(margin, 0 0 0 0);
        width: 100%;
      }
      width: 100%;
      @include swap_direction(margin, 5px 0 5px 0);
      @include swap_direction(padding, 0 0 0 0);
    }
    fieldset.address {
      div.phone_part {
        input {
          width: 90% !important;
        }
        margin-right: 5px !important;
      }
      #profile-postal {
        input {
          width: 35%;
        }
        width: 100%;
      }
    }
    .submit_btn {
      @include swap_direction(margin, 0 10px 0 10px);
    }
    font-size: 11px;
  }
  #address-lookup-container {
    .rna-list-header {
      li {
        width: 99.5%;
        display: block;
        float: #{$ldirection};
        border: 1px solid #f6f0e6;
        &:first {
          background-color: #ddd9c3;
        }
      }
      header1 {
        width: 73.7%;
        float: #{$ldirection};
        text-align: center;
        @include swap_direction(padding, 10px 0 10px 0);
      }
      header2 {
        float: #{$ldirection};
        text-align: center;
        @include swap_direction(padding, 10px 20px 10px 20px);
        border-left: 1px solid #f6f0e6;
      }
      display: none;
      text-align: center;
      font-weight: bold;
      font-size: 13px;
    }
    #rna-loading {
      z-index: 10000;
      position: fixed;
      top: 265px;
      right: 165px;
      display: none;
    }
    .address {
      .town-submit-btn {
        width: 15%;
        @include swap_direction(margin, 0 0 0 0);
        font-size: 10px;
        height: 25px;
        line-height: 25px;
      }
      @include swap_direction(padding, 5px 5px 5px 5px);
      border: none;
    }
    .address-search {
      border-bottom: 1px solid $color-cl-green;
      color: $color-black;
      @include swap_direction(margin, 0 5px 0 5px);
      @include swap_direction(padding, 0 0 5px 0);
      width: 97%;
      #rna_keyword {
        float: #{$ldirection};
        width: 82%;
        @include swap_direction(margin, 0 5px 0 0);
      }
      h4 {
        font-size: 16px;
        color: #46ab8c;
        text-align: center;
      }
      .keyword-hint {
        padding: 5px 0;
        font-weight: normal;
        clear: both;
      }
      h3 {
        font-size: 10px;
        font-weight: bold;
        text-align: center;
      }
    }
    #rna-page-list {
      float: #{$ldirection};
      @include swap_direction(margin, 0 0 0 0);
      @include swap_direction(padding, 10px 0 10px 0);
      width: 100%;
      text-align: center;
    }
    .error-msg {
      display: none;
      @include swap_direction(margin, 0 auto 0 auto);
      width: 40%;
      @include swap_direction(padding, 30px 0 30px 0);
    }
    .error_messages {
      display: none;
      @include swap_direction(margin, 0 auto 0 auto);
      @include swap_direction(padding, 30px 0 30px 0);
      width: 70%;
      color: $color-black;
    }
    .error-display {
      width: 95%;
      background-color: #f7f7f7;
      @include swap_direction(margin, 10px 10px 10px 10px);
    }
    .error_messages.error {
      @include swap_direction(margin, 0 auto 0 auto);
      width: 40%;
      @include swap_direction(padding, 30px 0 30px 0);
      li {
        color: $color-black;
        background-color: transparent;
      }
    }
    .address1 {
      display: block;
      @include swap_direction(padding, 5px 0 5px 0);
      cursor: pointer;
    }
    .address3 {
      cursor: pointer;
    }
    .rna-list {
      display: none;
      tbody {
        max-height: 215px;
        overflow-y: auto;
        overflow-x: hidden;
        display: inline-block;
      }
      .sno {
        width: 14px;
      }
      th.full-address {
        text-align: center;
      }
      .full-address {
        width: 500px;
      }
      .postal {
        width: 130px;
      }
      th {
        line-height: 15px;
        height: 15px;
      }
      tr {
        border-top: 1px solid $color-light-grey;
        border-bottom: 1px solid $color-light-grey;
        display: block;
      }
      .roadAddrPart1 {
        display: none;
      }
      .roadAddrPart2 {
        display: none;
      }
    }
    #address-hint-container {
      h4 {
        padding-left: 10px;
        font-weight: bold;
        font-size: 10px;
        @include swap_direction(margin, 10px 0 5px 0);
        color: $color-black;
      }
      background-color: $color-white;
      padding-top: 5px;
      .hint-table {
        width: 95%;
        border-collapse: collapse;
        @include swap_direction(margin, 0 10px 0 10px);
        border-top: 1px solid $color-light-grey;
        border-bottom: 1px solid $color-light-grey;
        @include swap_direction(padding, 10px 10px 10px 10px);
        font-size: 10px;
        .hint-table-header {
          border-top: 1px solid $color-light-grey;
          border-bottom: 1px solid $color-light-grey;
          @include swap_direction(padding, 10px 10px 10px 10px);
          font-size: 10px;
          background-color: #f7f7f7;
        }
        .hint-table-data {
          border-top: 1px solid $color-light-grey;
          border-bottom: 1px solid $color-light-grey;
          @include swap_direction(padding, 10px 10px 10px 10px);
          font-size: 10px;
        }
      }
      .building-name-registered {
        @include swap_direction(padding, 10px 10px 10px 10px);
        @include swap_direction(margin, 0 0 0 0);
      }
    }
    #address-results-container {
      background-color: $color-white;
      display: none;
      .rna-list {
        width: 100%;
        border-collapse: collapse;
        border: none !important;
        border-top: 1px solid $color-light-grey;
        border-bottom: 1px solid $color-light-grey;
        @include swap_direction(padding, 0 0 0 10px);
        font-size: 10px;
        th {
          border: none;
          border-top: 1px solid $color-light-grey;
          border-bottom: 1px solid $color-light-grey;
          @include swap_direction(padding, 10px 0 10px 5px);
          font-size: 10px;
          background-color: #f7f7f7;
        }
        td {
          border: none !important;
          border-top: 1px solid $color-light-grey;
          border-bottom: 1px solid $color-light-grey;
          @include swap_direction(padding, 10px 10px 10px 10px);
          font-size: 10px;
        }
      }
      zipcode {
        float: #{$rdirection};
      }
      .search-results-header {
        padding-left: 10px;
        color: $color-black;
        font-weight: bold;
        font-size: 10px;
      }
    }
    #rna-page-list {
      background-color: $color-white;
      padding-top: 5px;
    }
    #address-third-elements {
      .zip {
        display: none;
      }
    }
    #address-input-container {
      h4 {
        font-size: 14px;
        @include swap_direction(padding, 0 0 5px 10px);
        color: $color-black;
        font-weight: bold;
      }
      label {
        display: block;
        float: #{$ldirection};
        width: 25%;
        font-weight: bold;
      }
      .building {
        @include swap_direction(margin, 0 0 0 28%);
        .green-btn.small1.check-balance {
          @include swap_direction(margin, 10px 55px 10px 55px);
          float: #{$ldirection};
        }
      }
      ra3 {
        @include swap_direction(padding, 5px 0 5px 0);
        display: block;
      }
      .address-search-h {
        @include swap_direction(margin, 5px 0 5px 0);
      }
      #addr2_input_node {
        width: 75%;
      }
      .street {
        @include swap_direction(margin, 10px 10px 10px 10px);
        float: #{$ldirection};
        .ra1 {
          width: 75%;
          display: block;
          float: left;
        }
      }
      .detail-address {
        @include swap_direction(margin, 10px 10px 10px 10px);
      }
      .address-details {
        background-color: #f7f7f7;
        @include swap_direction(padding, 10px 10px 10px 10px);
        @include swap_direction(margin, 0 10px 0 10px);
        font-size: 10px;
      }
      .address-submit-button {
        @include swap_direction(padding, 10px 10px 10px 10px);
        width: 100%;
        .address-input-submit {
          @include swap_direction(margin, 0 auto 0 auto);
          width: 45%;
          height: 20px;
          line-height: 20px;
          font-size: 10px;
        }
      }
    }
  }
  .close-link {
    top: 25px;
    @include swap_direction(padding, 5px 5px 5px 5px);
    cursor: pointer;
    position: absolute;
    right: 25px;
  }
  min-width: 33%;
  background-color: $color-white;
}

#address-confirmation {
  h2 {
    font-size: 16px;
    color: $color-black;
    @include swap_direction(margin, 0 0 0 0);
  }
  .address-confirm-buttons {
    width: 60%;
    @include swap_direction(margin, 10px auto 10px auto);
    .address-confirm {
      width: 30%;
      float: #{$ldirection};
      @include swap_direction(margin, 0 10px 0 10px);
      .button {
        @include swap_direction(margin, 0 0 0 0);
        font-size: 12px;
        line-height: 23px;
        height: 23px;
      }
    }
    .address-cancel {
      float: #{$ldirection};
      width: 50%;
      .grey-button {
        font-family: $base-bold-font-family;
        background-color: #999;
        text-transform: uppercase;
        color: $color-white;
        line-height: 1.5;
        font-size: 0.9em;
        text-align: center;
        letter-spacing: 0;
        border: 0;
        @include swap_direction(padding, 4px 10px 3px 10px);
        display: inline-block;
        zoom: 1;
      }
      .update-cancel {
        height: 23px;
        @include swap_direction(margin, 0 0 0 0);
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
  width: 100%;
  text-align: center;
  .change-address-content {
    width: 75%;
    @include swap_direction(padding, 10px 0 10px 0);
    line-height: 18px;
    @include swap_direction(margin, 0 auto 0 auto);
  }
}

#shipping {
  .grey-button {
    display: block;
    text-align: center;
    width: auto;
    background-color: #999;
    color: $color-white;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    @include swap_direction(padding, 10px 3px 10px 3px);
    font-weight: bold;
    @include swap_direction(margin, 8px 0 8px 0);
    height: 38px;
    line-height: 12px;
  }
}

.device-mobile {
  #foreground-node {
    .close-container {
      right: -15px;
      top: -15px;
      position: absolute;
    }
    @include swap_direction(padding, 20px 10px 20px 10px);
  }
}

#main.multi {
  fieldset.fs {
    .input_address_1 {
      margin: 0;
    }
    .input_address_2 {
      margin: 0;
    }
    .input_address_3 {
      margin: 0;
    }
  }
}

#order_return {
  label {
    width: 98%;
    display: block;
  }
  .phone {
    input {
      width: 20%;
    }
  }
  .clearfix {
    overflow: visible;
    margin: 10px 0;
  }
  #form--address--field--POSTAL_CODE {
    float: left;
  }
  .fieldset-note {
    float: left;
    .grey_button {
      display: block;
      text-align: center;
      width: auto;
      background-color: #999;
      color: white;
      cursor: pointer;
      text-decoration: none;
      text-transform: uppercase;
      padding: 10px 10px 10px 10px;
      font-weight: bold;
      margin: 0 0 0 10px;
      height: 27px;
      line-height: 6px;
    }
  }
}

body#viewcart.device-mobile {
  #top {
    position: relative;
  }
}

.section-international-sites {
  .field-mobile-template {
    .basic-content-mobile-v1 {
      .content {
        max-height: none;
      }
    }
  }
}

.order-details {
  li {
    a {
      font-weight: bold;
      font-size: 0.9em;
      background-color: $color-black;
      color: $color-white;
      line-height: 1.5;
      padding: 4px 10px 3px;
      display: inline-block;
      text-transform: uppercase;
    }
  }
}

.account-header-panel {
  .outer-wrap {
    header {
      width: auto;
    }
  }
}

#password_reset {
  .password_2_container,
  .password_container {
    float: none;
  }
}
